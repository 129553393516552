import React from 'react'
  import GuradoLogo from "../assets/logo.svg";
  import '../styles/fonts.css';
  import "../styles/landing.css";
  import { Container, Row, Col, } from 'react-bootstrap';
  import SideBar from './sidebar';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCheck } from '@fortawesome/free-solid-svg-icons'

const SuccessPage = () => {
    return (
    <Container fluid className="h-100">
      <Row className="h-100 w-100">
        <Col sm={6} style={{height:"100%", maxWidth:"530px"}} className="d-none d-lg-block">
          <SideBar />
        </Col>
        <Col>
            <div className="content-wrapper center success" >
              <span className="descr"><b>
                  <FontAwesomeIcon icon={faCheck} size="lg" color="#23bade" 
                    style={{marginTop: "22px", marginLeft: "5px", marginRight:"10px"}} />
                    Erfolg</b></span><br /><br />
                    <span className="successtext">
                    Dein Erstattungsantrag wurde erfolgreich übermittelt und wird nun von uns bearbeitet. Bei erfolgreicher Bearbeitung erhältst du innerhalb der nächsten 7 Werktage eine Auszahlungsbestätigung per E-Mail.
                    </span>
            </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SuccessPage;