import React from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import GuradoLogo from "../assets/logo.svg";
import "../styles/fonts.css";
import "../styles/landing.css";
import Loader from "react-loader-spinner";
import { Container, Row, Col } from "react-bootstrap";
import SideBar from "./sidebar";

const LandingPage = ({ api, Auth }) => {
  let history = useHistory();
  const [isEmailSent, setEmailSent] = React.useState(false);
  const [mail, setMail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);

  const sendMail = () => {
    setLoading(true);
    api
      .post("refundapi", "/sendMail", {
        body: {
          email: mail,
        },
      })
      .then((response) => {
        console.log(response);
        setEmailSent(true);
        setLoading(false);
      });
  };

  const verifyCode = () => {
    setLoading(true);

    api
      .post("refundapi", "/verifyCode", {
        body: {
          code: code.replace(/\s/g, ""),
          mail: mail.replace(/\s/g, ""),
        },
        response: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          sessionStorage.setItem("code", code.replace(/\s/g, ""));
          sessionStorage.setItem("mail", mail.replace(/\s/g, ""));
          Auth.authenticate(function () {
            history.push("/form");
          });
        }
      });
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100 w-100">
        <Col
          sm={6}
          style={{ height: "100%", maxWidth: "530px" }}
          className="d-none d-lg-block"
        >
          <SideBar />
        </Col>
        <Col>
          <div className="content-wrapper center">
            <img src={GuradoLogo} />
            <br />
            <span className="descr">
              <b>Rückerstattung beantragen</b>
            </span>
            <br />
            <br />
            {!isLoading ? (
              <div>
                <span style={{ fontSize: "18px" }}>
                  {!isEmailSent ? "E-Mail:" : "Verifizierungscode:"} <br />
                </span>
                {!isEmailSent ? (
                  <input
                    id="if_1"
                    type="email"
                    onChange={(e) => setMail(e.target.value)}
                    className="tf_email"
                  />
                ) : (
                  <span>
                    <input
                      type="text"
                      className="tf_email"
                      defaultValue=""
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </span>
                )}
                <input
                  id="if_2"
                  type="submit"
                  onClick={!isEmailSent ? sendMail : verifyCode}
                  value={
                    !isEmailSent ? "Verifizierungscode senden" : "Bestätigen"
                  }
                  style={{ marginLeft: "10px" }}
                />
              </div>
            ) : (
              <div>
                <Loader
                  type="Oval"
                  color="#30b3da"
                  height={50}
                  width={50}
                ></Loader>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default LandingPage;
