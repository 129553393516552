import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import Amplify, {
  API
} from "aws-amplify";
import awsExports from "./aws-exports";
import LandingPage from './components/landing';
import FormComponent from './components/form';
import 'bootstrap/dist/css/bootstrap.min.css';
import SuccessPage from './components/success';

Amplify.configure(awsExports);

const apiName = 'refundapi';
const path = '/test';

function PrivateRoute({ children, ...rest }) {
  return (
    <Route {...rest} render={({ location }) => {
      return (Auth.isAuthenticated === true || sessionStorage.getItem('code') !== null)
        ? children
        : <Redirect to={{
            pathname: '/',
            state: { from: location }
          }} />
    }} />
  )
}

function Login() {
  const [
    redirectToReferrer,
    setRedirectToReferrer
  ] = React.useState(false)

  const { state } = useLocation()

  const login = () => Auth.authenticate(() => {
    setRedirectToReferrer(true)
  })

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || '/'} />
  }

  return (
    <span>
      <p>You must log in to view the page</p>
      <button onClick={login}>Log in</button>
    </span>
  )
}

  const Auth = {
    isAuthenticated: false,
    authenticate(cb) {
      this.isAuthenticated = true
      setTimeout(cb, 100) // fake async
    },
    signout(cb) {
      this.isAuthenticated = false
      setTimeout(cb, 100) // fake async
    }
  }

ReactDOM.render(
  <Router>
  <div>
    <Route exact path="/">
      <LandingPage api={API} Auth={Auth}/>
    </Route>
    <PrivateRoute path='/form'>
      <FormComponent api={API}/>
    </PrivateRoute>
    <Route exact path="/success">
      <SuccessPage />
    </Route>
  </div>
</Router>,
  document.getElementById('root')
);

reportWebVitals();