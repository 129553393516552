import React from "react";
import { Container, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import "../styles/sidebar.css";
import BoxesImg from "../assets/boxes.PNG";
import ThreeBoxes from "../assets/threeboxes.PNG";
import SidebarImg from "../assets/sb.svg";
import '../styles/fonts.css';

const SideBar = () => {
    return <Row className="h-100 sb-wrapper">
        <Col className="h-100">
            <img src={BoxesImg} className="boxes"/>
            <div className="spacer"> </div>
            <div className="header">
                <b>
                Rückerstattung <br />
                beantragen
                </b>
            </div>
            <img src={SidebarImg} className="sidebarImg"/><br />
            <div className="sbfooter w-100 text-center">
                <ul className="list-inline">
                    <li className="list-inline-item">2021 ©</li>
                    <li className="list-inline-item"><a href="https://site.gurado.de">gurado GmbH</a></li>
                    <li className="list-inline-item"><a href="https://site.gurado.de/fileadmin/AGB.pdf">AGB</a></li>
                    <li className="list-inline-item"><a href="https://site.gurado.de/ueber-uns/datenschutzerklaerung/">Datenschutz</a></li>
                </ul>
            </div>
        </Col>
    </Row>
}
export default SideBar;