import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import GuradoLogo from "../assets/logo.svg";
import "../styles/landing.css";
import { Form, Col, Button, InputGroup, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./sidebar";
import Loader from "react-loader-spinner";
const IBAN = require("iban");

const FormComponent = ({ api }) => {
  let history = useHistory();

  const [voucherAmount, setVoucherAmount] = React.useState(1);
  const [vouchers, setVouchers] = React.useState(new Array());
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [plz, setPlz] = React.useState("");
  const [city, setCity] = React.useState("");
  const [iban, setIban] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);

  const addAdditionalVoucher = () => {
    if (voucherAmount < 3) {
      setVoucherAmount(voucherAmount + 1);
      console.log(voucherAmount);
    }
  };

  const removeVoucher = () => {
    if (voucherAmount > 1) {
      setVoucherAmount(voucherAmount - 1);
    }
  };

  const voucherRegexCheck = (e, i) => {
    //prevent manual hyphen
    if (e.keyCode === 189) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      return;
    }
    //backspace action
    if (e.keyCode === 8) {
      let input_length = e.target.value.length;
      if (input_length === 4 || input_length === 9 || input_length === 14) {
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        return;
      }
    }
    //add hyphen
    if (
      e.target.value.length === 4 ||
      e.target.value.length === 9 ||
      e.target.value.length === 14
    ) {
      e.target.value += "-";
    }
    //update state with input
    if (vouchers.length >= i) {
      let pVouchers = vouchers;
      pVouchers[i] = e.target.value;
      setVouchers(pVouchers);
    } else {
      let pVouchers = vouchers;
      while (pVouchers.length < i - 1) {
        pVouchers.push("");
      }
      pVouchers.push(e.target.value);
      setVouchers(pVouchers);
    }
  };

  const sendForm = (e) => {
    e.preventDefault();

    let checkedIban = iban.replace(/\s/g, "");
    let checkedVouchers = vouchers.map((voucher) => voucher.replace(/\s/g, ""));

    //form validation
    if (plz.length <= 4 || isNaN(plz)) {
      console.log(plz.length);
      alert("Bitte gültige PLZ eingeben");
      return;
    }
    if (!firstname.length > 0 || !lastname.length > 0) {
      //invalid name
      alert("Bitte geben Sie einen gültigen Vor- und Nachnamen an.");
      return;
    }
    let vouchers_valid = true;
    vouchers.forEach((v) => {
      if (v.length !== 19) {
        vouchers_valid = false;
      }
    });
    if (vouchers.length === 0) vouchers_valid = false;
    if (!vouchers_valid) {
      alert("Ein oder mehrere Gutscheincodes sind ungültig");
      return;
    }
    if (!IBAN.isValid(checkedIban)) {
      alert("Bitte eine gültige IBAN angeben.");
      return;
    }
    if (city.length < 3) {
      alert("Bitte gültigen Ort eingeben.");
      return;
    }
    console.log(plz.length);
    console.log(isNaN(plz));
    setLoading(true);
    api
      .post("refundapi", "/sendForm", {
        body: {
          voucher_codes: vouchers,
          firstname: firstname,
          lastname: lastname,
          address: address,
          plz: plz,
          city: city,
          iban: checkedIban,
          email: sessionStorage.getItem("mail"),
        },
        response: true,
      })
      .then((response) => {
        if (response.status === 200) {
          history.push("/success");
        } else {
          alert("Oops! Da ist irgendwas schiefgelaufen.");
        }
      });
  };

  if (sessionStorage.getItem("code") == null) {
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("mail");
    history.push("/");
  }

  let pItems = [];
  for (let i = 0; i < voucherAmount; i++) {
    pItems.push(
      <Form.Group controlId={`formGridVouchercode${i}`} key={`voucherItem${i}`}>
        <Form.Label>Gutscheincode</Form.Label>
        <InputGroup>
          <Form.Control
            placeholder="ABCD-1234-EFGH-5678"
            onKeyUp={(e) => voucherRegexCheck(e, i)}
            maxLength="19"
          />
          {i === voucherAmount - 1 && voucherAmount > 1 && (
            <span onClick={removeVoucher}>
              <FontAwesomeIcon
                icon={faMinusCircle}
                size="lg"
                color="red"
                style={{ marginTop: "22px", marginLeft: "5px", cursor: "hand" }}
              />
            </span>
          )}
          {voucherAmount === 2 && <br />}
          {i === voucherAmount - 1 && voucherAmount < 3 && (
            <span onClick={addAdditionalVoucher}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="lg"
                color="blue"
                style={{ marginTop: "22px", marginLeft: "5px", cursor: "hand" }}
              />
            </span>
          )}
        </InputGroup>
      </Form.Group>
    );
  }

  return (
    <Container fluid className="h-100">
      <Row className="h-100 w-100">
        <Col
          sm={6}
          style={{ height: "100%", maxWidth: "530px" }}
          className="d-none d-lg-block"
        >
          <SideBar />
        </Col>
        <Col>
          <div className="f-content">
            <img src={GuradoLogo} />
            <br />
            <span className="descr">
              <b>Rückerstattung beantragen</b>
            </span>
            <br />
            <br />

            {!isLoading ? (
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridFirstname">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control
                      placeholder="Max"
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridLastname">
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control
                      placeholder="Mustermann"
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridAddress1">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    placeholder="Musterstrasse 24"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridZip">
                    <Form.Label>PLZ</Form.Label>
                    <Form.Control
                      placeholder="44287"
                      onChange={(e) => setPlz(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>Ort</Form.Label>
                    <Form.Control
                      placeholder="Dortmund"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridIban">
                  <Form.Label>IBAN</Form.Label>
                  <Form.Control
                    onChange={(e) => setIban(e.target.value)}
                    placeholder="DE75512108001245126199"
                  />
                </Form.Group>

                {pItems}

                <input
                  type="submit"
                  onClick={(e) => sendForm(e)}
                  value="Erstattung beantragen"
                ></input>
              </Form>
            ) : (
              <Loader
                type="Oval"
                color="#30b3da"
                height={50}
                width={50}
              ></Loader>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default FormComponent;
